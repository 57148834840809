import React from 'react'
import { bracketsToHtml } from '@shared/universal/utils/stringUtils'

import {
  selectPopularDestinations,
  selectThemes,
} from '../../../../stores/selectors'

import { Item } from './Item'
import { List } from './List'
import { LinkList } from './LinkList'

export type Props = Record<string, unknown>

export const PopularDestinations = () => {
  const themes = selectThemes()
  const popularDestinations = selectPopularDestinations()

  return (
    <>
      <LinkList
        className='mt-12'
        title={
          <h2
            className='font-light'
            dangerouslySetInnerHTML={{
              __html: bracketsToHtml(popularDestinations.title),
            }}
          />
        }
      >
        {[
          popularDestinations.regions,
          popularDestinations.provinces,
          popularDestinations.coasts,
          popularDestinations.areas,
        ].map(({ title, items }) => (
          <List className='w-1/2 md:w-1/4' key={title} title={title}>
            {items.map(({ text, href }) => (
              <Item key={href} title={text} url={href} />
            ))}
          </List>
        ))}
      </LinkList>
      {themes && (
        <LinkList
          className='mb-10 md:mb-20'
          title={
            <h2
              className='font-light mb-1'
              dangerouslySetInnerHTML={{
                __html: bracketsToHtml(themes.title),
              }}
            />
          }
        >
          <List className='w-1/2 md:w-1/4'>
            {themes.items.map(({ text, href }) => (
              <Item key={href} title={text} url={href} />
            ))}
          </List>
        </LinkList>
      )}
    </>
  )
}
