import React from 'react'
import { getButtonClassnames } from '@shared/universal/utils/buttonUtils'

export type Props = {
  children: React.ReactNode
  className: string
  disabled?: boolean
  onClick: () => void
}

export const ShowMoreButton = ({
  children,
  onClick,
  disabled,
  className,
}: Props) => (
  <button
    disabled={disabled}
    type='button'
    className={getButtonClassnames(
      disabled,
      'bg-grey-light text-white py-2 text-sm hover:bg-primary transition-colors border-none',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </button>
)
