import cx from 'classnames'
import { TypeOf } from 'io-ts'
import React, { useCallback, useMemo } from 'react'

import { AutoCompleteItem } from '../decoders/AutoCompleteDecoder'

export type Props = {
  item: TypeOf<typeof AutoCompleteItem>
  isSelected?: boolean
  onClick: (item: TypeOf<typeof AutoCompleteItem>) => void
}

export const Item = ({ item, isSelected, onClick }: Props) => {
  const { mainPart, value = '' } = item

  const [part1, part2] = useMemo(() => {
    return typeof mainPart === 'string'
      ? [mainPart, value.replace(mainPart, '')]
      : [value.split(/(,.+)/)[0], value.split(/(,.+)/)[1]]
  }, [mainPart, value])

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    ev => {
      ev.preventDefault()
      onClick(item)
    },
    [onClick, item],
  )

  return (
    <button
      type='button'
      className={cx(
        'appearance-none outline-none border-0 block w-full text-left cursor-pointer hover:bg-grey-lighter',
        {
          'bg-grey-lighter': isSelected,
        },
      )}
      onMouseDown={handleClick}
    >
      <div className='flex my-0 mx-2 text-lg overflow-hidden overflow-ellipsis h-12 lg:flex-row lg:justify-between items-center'>
        <span className='overflow-hidden whitespace-nowrap overflow-ellipsis mr-4'>
          <span className='text-lg font-bold'>{part1}</span>
          {part2 && <span className='text-base lg:mt-0'>{part2}</span>}
        </span>
      </div>
    </button>
  )
}
