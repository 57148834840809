import React, { ButtonHTMLAttributes } from 'react'
import cx from 'classnames'

export type Props = {
  isActive?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

export const SearchBarDropdownButton = React.forwardRef<
  HTMLButtonElement,
  Props
>(({ isActive, className, children, ...others }, ref) => {
  return (
    <button
      type='button'
      ref={ref}
      className={cx(
        'appearance-none outline-none bg-white text-left px-3 cursor-pointer rounded-md border-2 border-solid',
        {
          'border-calendar-checkin-checkout-day': isActive,
          'border-transparent': !isActive,
        },
        className,
      )}
      {...others}
    >
      {children}
    </button>
  )
})

SearchBarDropdownButton.displayName = 'SearchBarDropdownButton'
