import React from 'react'

import { useIntl } from '../../utils/intl'
import { messages } from '../messages'

import { AgeSelect } from './AgeSelect'

export type Props = {
  className?: string
  ages: (number | undefined)[]
  onChange: (idx: number, value?: number) => void
}

export const AgeSelects = ({ className, ages, onChange }: Props) => {
  const format = useIntl()

  return (
    <div className={className}>
      {ages.map((value, idx) => (
        <AgeSelect
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          className={idx === ages.length - 1 ? '' : 'mb-2'}
          value={value}
          placeholder={`${format(messages.AGE)} ${idx}`}
          onChange={val => onChange(idx, val)}
        />
      ))}
    </div>
  )
}
