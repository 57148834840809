import { Store as DomainContextStore } from '@holidu/lib/server/domainContext'
import { MessageData } from '@holidu/lib/server/intl/types'
import { Store as RequestContextStore } from '@holidu/lib/server/requestContext'
import { CURRENCIES } from 'consts/universal'
import { TypeOf } from 'io-ts'
import { Store } from 'pullstate'

import { PageData, Url } from '../decoders/PageData'

export type Currency = {
  code: string
}

export type AppStore = {
  availableCurrencies: Currency[]
  currentDomain: TypeOf<typeof Url>
  pageData: TypeOf<typeof PageData>
  isDesktop: boolean
  currency: RequestContextStore['currency']
  domainConfig: DomainContextStore['domainConfig']
  uuid: RequestContextStore['uuid']
  userId: RequestContextStore['userId']
  locale: RequestContextStore['locale']
  messageData: MessageData
  matchedHost: string
}

// using `any` here as we want to update the store at the first moment
// and therefore taking the shortcut that all the above values are present
// and the type is valid
export const AppStore = new Store<AppStore>({
  availableCurrencies: CURRENCIES,
} as any)
