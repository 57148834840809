import React, { useCallback, useRef } from 'react'

import { useIntl } from '../../../utils/intl'
import { messages } from '../../messages'
import { Close } from '../SVGs/Close'
import { Search } from '../SVGs/Search'

type Props = {
  children: React.ReactNode
  className?: string
  value: string
  isFocused: boolean
  onChange: (value: string) => void
  onClear: React.MouseEventHandler<HTMLButtonElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onFocus: React.FocusEventHandler<HTMLFormElement>
  onBlur: React.FocusEventHandler<HTMLFormElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

export const Form = ({
  children,
  className,
  value,
  isFocused,
  onChange,
  onClear,
  onSubmit,
  onFocus,
  onBlur,
  onKeyDown,
}: Props) => {
  const format = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFocus = useCallback<React.FocusEventHandler<HTMLFormElement>>(
    ev => {
      inputRef.current?.focus()
      onFocus(ev)
    },
    [onFocus, inputRef],
  )

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ev => {
      onChange(ev.target.value)
    },
    [onChange],
  )

  return (
    <form
      action='/redirect/s'
      autoComplete='off'
      className={`w-full py-2 relative bg-white flex justify-center items-center rounded-md 
      border-2 border-solid border-transparent focus-within:border-primary ${className}`}
      method='GET'
      role='presentation'
      onSubmit={onSubmit}
      onFocus={handleFocus}
      onBlur={onBlur}
    >
      <div className='flex flex-col flex-1 mx-2'>
        <div className='flex items-center uppercase text-xxs'>
          {format(messages.DESTINATION)}
        </div>

        <input
          ref={inputRef}
          className='border-0 line-height-normal w-full overflow-ellipsis py-0 px-0 font-bold placeholder-grey-dark'
          value={value}
          name='SEARCH_INPUT'
          placeholder={format(messages.INPUT_DESTINATION)}
          type='text'
          onKeyDown={onKeyDown}
          onChange={handleChange}
          autoComplete='off'
          aria-label='Search'
        />
      </div>
      <button
        className={`border-0 h-full px-2 pointer-events-auto cursor-pointer ${
          isFocused && value ? 'block' : 'hidden'
        }`}
        type='button'
        onMouseDown={onClear}
      >
        <div className='bg-grey-light rounded-full w-6 h-6 flex items-center justify-center'>
          <Close className='text-white w-4 h-4' />
        </div>
      </button>
      <button
        className={`border-0 justify-center h-full px-2 cursor-pointer ${
          isFocused && value ? 'hidden' : 'block'
        }`}
        type='button'
      >
        <Search className='text-black-main w-6 h-6' />
      </button>
      {children}
    </form>
  )
}
