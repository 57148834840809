import { TypeOf } from 'io-ts'
import React from 'react'

import {
  AutoCompleteItem,
  AutoCompleteItems,
} from '../decoders/AutoCompleteDecoder'

import { Item } from './AutocompleteItem'

type Props = {
  autocompleteItems: TypeOf<typeof AutoCompleteItems> | null | undefined
  hasGoogleResults?: boolean
  selectedIndex?: number
  onClick: (arg0: TypeOf<typeof AutoCompleteItem>) => void
}

export const Autocomplete = React.memo(
  ({ autocompleteItems, hasGoogleResults, selectedIndex, onClick }: Props) => (
    <div
      className='absolute top-full mt-0.5 left-0 right-0 bg-white rounded-md 
      rounded-t-none z-search-bar-autocomplete border border-solid border-grey-light
      shadow-md lg:cursor-pointer'
    >
      {autocompleteItems && (
        <>
          {autocompleteItems.map((item, idx) => (
            <Item
              isSelected={idx === selectedIndex}
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              item={item}
              onClick={onClick}
            />
          ))}
          {hasGoogleResults && (
            <div className='text-right font-normal text-grey p-2 text-xs bg-white rounded rounded-t-none'>
              Powered By Google
            </div>
          )}
        </>
      )}
    </div>
  ),
)

Autocomplete.displayName = 'Autocomplete'
