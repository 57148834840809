import cx from 'classnames'
import React from 'react'

import { useIntl } from '../../utils/intl'
import { messages } from '../messages'

export type Props = {
  className?: string
  value: number | undefined
  placeholder: string
  onChange: (value?: number) => void
}

export const AgeSelect = ({
  className,
  value,
  placeholder,
  onChange,
}: Props) => {
  const format = useIntl()

  return (
    <select
      className={cx(
        'block w-full rounded bg-white text-grey-dark border border-solid border-grey-light text-xs',
        className,
      )}
      value={value}
      onChange={ev =>
        onChange(ev.target.value ? Number(ev.target.value) : undefined)
      }
    >
      <option value=''>{placeholder}</option>
      {[...Array(17).keys()].map(age => (
        <option key={age} value={age}>
          {`${age} ${format(messages.YEARS)}`}
        </option>
      ))}
    </select>
  )
}
