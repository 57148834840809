import cx from 'classnames'
import React, { AnchorHTMLAttributes } from 'react'

export type Props = AnchorHTMLAttributes<HTMLAnchorElement>

export const FooterLink = ({ children, className, ...others }: Props) => (
  <a className={cx('text-white', 'footer-text-sm', className)} {...others}>
    {children}
  </a>
)
