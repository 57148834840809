import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { TypeOf } from 'io-ts'

import {
  AutoCompleteItem,
  AutoCompleteItems,
} from '../decoders/AutoCompleteDecoder'

import { Form } from './Form'
import { Autocomplete } from './Autocomplete'

export type Props = {
  className?: string
  value: string
  autocompleteItems: TypeOf<typeof AutoCompleteItems> | null | undefined
  hasGoogleResults?: boolean
  onAutocompleteItemClick?: (item: TypeOf<typeof AutoCompleteItem>) => void
  onChange: (value: string) => void
  onClear: React.MouseEventHandler<HTMLButtonElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onFocus: () => void
  onBlur: () => void
  isFocused?: boolean
}

export const SearchField = ({
  className,
  value,
  autocompleteItems,
  hasGoogleResults,
  onAutocompleteItemClick,
  onClear,
  onSubmit,
  onChange,
  onFocus,
  onBlur,
  isFocused,
}: Props) => {
  const [selectIndex, setSelectIndex] = useState<number>()

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = ev => {
    switch (ev.key) {
      case 'ArrowUp':
      case 'ArrowDown':
        ev.preventDefault()
        if (autocompleteItems) {
          const isUp = ev.key === 'ArrowUp'
          if (
            (isUp || selectIndex !== autocompleteItems.length - 1) &&
            (!isUp || selectIndex !== 0)
          ) {
            setSelectIndex(index =>
              index === undefined ? 0 : index + (isUp ? -1 : 1),
            )
          }
        }
        break

      case 'Enter':
        ev.preventDefault()
        if (autocompleteItems && selectIndex !== undefined) {
          handAutocompleteItemClick(autocompleteItems[selectIndex])
        }
        break
    }
  }

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    ev => {
      ev.preventDefault()
      onSubmit(ev)
    },
    [onSubmit],
  )

  const handAutocompleteItemClick = useCallback<
    (item: TypeOf<typeof AutoCompleteItem>) => void
  >(
    item => {
      onChange(item.value)
      onAutocompleteItemClick?.(item)
    },
    [onAutocompleteItemClick, onChange],
  )

  const isAutocompleteVisible = isFocused && Boolean(autocompleteItems?.length)

  return (
    <Form
      className={cx({ 'rounded-b-none': isAutocompleteVisible }, className)}
      onChange={onChange}
      onClear={onClear}
      onSubmit={handleSubmit}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      isFocused={Boolean(isFocused)}
      onKeyDown={handleKeyDown}
    >
      {isAutocompleteVisible && (
        <Autocomplete
          autocompleteItems={autocompleteItems}
          hasGoogleResults={hasGoogleResults}
          onClick={handAutocompleteItemClick}
          selectedIndex={selectIndex}
        />
      )}
    </Form>
  )
}
