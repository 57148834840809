import React, { memo, useCallback } from 'react'
import cx from 'classnames'

export type Props = {
  day: number
  time: number
  isCheckin?: boolean
  isCheckout?: boolean
  isBetween?: boolean
  isUnavailable?: boolean
  isStrong?: boolean
  onClick?: (day: Date) => void
  onMouseEnter?: (day: Date) => void
}

export const Day = memo(
  ({
    day,
    time,
    isCheckin,
    isCheckout,
    isBetween,
    isUnavailable,
    isStrong,
    onClick,
    onMouseEnter,
  }: Props) => {
    const handleClick = useCallback(
      () => onClick?.(new Date(time)),
      [onClick, time],
    )
    const handleMouseEnter = useCallback(
      () => onMouseEnter?.(new Date(time)),
      [onMouseEnter, time],
    )

    const isCheckinOrCheckout = isCheckin || isCheckout

    return (
      <button
        type='button'
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        className={cx(
          'flex-shrink-0 block border-none p-0 appearance-none outline-none w-1/7 relative text-black-main px-0',
        )}
      >
        <div className='pb-full' />
        <div
          className={cx('absolute top-1 left-0 right-0 bottom-1', {
            'z-calendar-day-background': !isCheckinOrCheckout,
            'z-calendar-day-background-active': isCheckinOrCheckout,
            'bg-calendar-between-day text-calendar-between-day-dark rounded-full':
              isCheckinOrCheckout,
            'rounded-r-none': isCheckin,
            'rounded-l-none': isCheckout,
          })}
        />
        <div
          className={cx(
            'absolute flex items-center justify-center top-0 left-0 right-0 bottom-0',
            {
              'z-calendar-day': !isCheckinOrCheckout,
              'z-calendar-day-active': isCheckinOrCheckout,
              'hover:bg-grey-lighter':
                !isCheckinOrCheckout && !isBetween && !isUnavailable,
              'font-semibold bg-calendar-checkin-checkout-day text-white shadow-calendar-day':
                isCheckinOrCheckout,
              'bg-calendar-between-day text-calendar-between-day-dark top-1 bottom-1':
                isBetween && !isCheckinOrCheckout,
              'rounded-full': !isBetween || isCheckinOrCheckout,
              'text-grey': isUnavailable,
              'cursor-pointer': !isUnavailable,
              'font-semibold': isStrong,
            },
          )}
        >
          {day}
        </div>
      </button>
    )
  },
)

Day.displayName = 'Day'
