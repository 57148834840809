import React from 'react'
import cx from 'classnames'

import { FooterTitle } from './FooterTitle'

export type Props = {
  title: string
  children: React.ReactNode
  className?: string
  listClassName?: string
  itemClassName?: string
}

export const FooterLinks = ({
  title,
  children,
  className,
  listClassName,
  itemClassName,
}: Props) => (
  <div className={className}>
    <FooterTitle>{title}</FooterTitle>
    <ul className={cx('list-none', 'm-0', 'p-0', listClassName)}>
      {React.Children.map(children, (link, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={cx('footer-mb-1', itemClassName)} key={idx}>
          {link}
        </li>
      ))}
    </ul>
  </div>
)
