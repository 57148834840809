import React from 'react'
import cx from 'classnames'

export type Props = {
  className?: string
  buttons: React.ReactNode
  children: React.ReactNode
  isMenuOpen: boolean
}

export const SearchBarDropdown = ({
  className,
  children,
  buttons,
  isMenuOpen,
}: Props) => {
  return (
    <div className={cx('md:flex relative', className)}>
      <div className='flex flex-1'>{buttons}</div>
      <div
        className={cx(
          'md:absolute overflow-hidden rounded-md md:top-full z-calendar lg:left-1/2 lg:transform lg:-translate-x-1/2 mt-3 md:mt-4 relative shadow-calendar',
          {
            hidden: !isMenuOpen,
          },
        )}
      >
        {children}
      </div>
    </div>
  )
}
