import { useCallback, useEffect } from 'react'

export const useOnClickOutside = <T extends React.RefObject<HTMLElement>[]>(
  refs: T,
  handler?: () => void,
) => {
  const listener = useCallback(
    (ev: Event) => {
      for (const ref of refs) {
        if (!ref.current || ref.current.contains(ev.target as Node)) {
          return
        }
      }
      handler?.()
    },
    [handler, refs],
  )

  const removeEventListeners = useCallback(() => {
    document.removeEventListener('mousedown', listener)
    document.removeEventListener('touchstart', listener)
  }, [listener])

  useEffect(() => {
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return removeEventListeners
  }, [refs, handler, listener, removeEventListeners])

  return removeEventListeners
}
