import React from 'react'
import cx from 'classnames'

import {
  SearchBarDropdownButton,
  Props as SearchBarDropdownButtonProps,
} from '../SearchBarDropdown/SearchBarDropdownButton'
import { ChevronRight } from '../SVGs/ChevronRight'
import { messages } from '../messages'
import { useIntl } from '../../utils/intl'

export type Props = {
  guests: number
  bedrooms: number
} & SearchBarDropdownButtonProps

export const PersonPickerButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    { placeholder, className, children, isActive, guests, bedrooms, ...others },
    ref,
  ) => {
    const format = useIntl()

    return (
      <SearchBarDropdownButton
        ref={ref}
        isActive={isActive}
        className={cx(
          'relative py-1 w-full md:w-44 min-h-search-bar-row',
          className,
        )}
        {...others}
      >
        <div className='flex items-center justify-between'>
          <div
            className={cx('font-bold', {
              'text-sm': guests && bedrooms,
              'text-base': !guests || !bedrooms,
            })}
          >
            {guests > 0 && (
              <div>
                {guests > 1
                  ? `${guests} ${format(messages.PERSONS)}`
                  : `1 ${format(messages.PERSON)}`}
              </div>
            )}
            {bedrooms > 0 && (
              <div>
                {bedrooms > 1
                  ? `${bedrooms} ${format(messages.SLEEPING_ROOMS)}`
                  : `1 ${format(messages.SLEEPING_ROOM)}`}
              </div>
            )}
          </div>
          <div>
            <ChevronRight
              className={cx('w-4 h-4 transition-transform transform', {
                '-rotate-90': isActive,
                'rotate-90': !isActive,
              })}
            />
          </div>
        </div>
      </SearchBarDropdownButton>
    )
  },
)

PersonPickerButton.displayName = 'PersonPickerButton'
