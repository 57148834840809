import { useEffect, useState } from 'react'

export const usePersonPicker = (
  defaultAdults = 2,
  defaultKids = 0,
  defaultBedrooms = 0,
) => {
  const [adults, setAdults] = useState(defaultAdults)
  const [kids, setKids] = useState(defaultKids)
  const [bedrooms, setBedrooms] = useState(defaultBedrooms)
  const [kidsAges, setKidsAges] = useState<(number | undefined)[]>([])

  useEffect(() => {
    setKidsAges(ages => {
      const newAges = [...ages]
      if (newAges.length > kids) {
        newAges.pop()
      } else if (newAges.length < kids) {
        newAges.push(undefined)
      }
      return newAges
    })
  }, [kids])

  const setKidsAge = (idx: number, newAge?: number) => {
    setKidsAges(kidsAges =>
      kidsAges.map((age, i) => (i === idx ? newAge : age)),
    )
  }

  return {
    adults,
    kids,
    bedrooms,
    kidsAges,
    setAdults,
    setKids,
    setBedrooms,
    setKidsAge,
  }
}
