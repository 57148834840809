import React from 'react'

import { selectCurrentDomain } from '../../../stores/selectors'

export type Props = Record<string, unknown>

export const ListWithUsLink = () => {
  const currentDomain = selectCurrentDomain()

  if (!currentDomain.href.includes('.com')) {
    return null
  }

  return (
    <div className='container text-center mb-10'>
      <a
        href='https://promo.holidu.com/spain-holiday'
        className='text-grey-dark hover:text-primary transition-colors text-sm'
      >
        <img
          alt=''
          className='max-w-list-with-us-link w-full'
          src={`${process.env.PUBLIC_PATH}assets/images/list_with_us_en.png`}
        />
      </a>
    </div>
  )
}
