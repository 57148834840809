import React from 'react'

import { selectCurrentDomain } from '../../../stores/selectors'

export const SpainMap = () => {
  const currentDomain = selectCurrentDomain()

  return (
    <section className='hidden lg:flex container justify-center'>
      <embed
        id='map'
        src={`${process.env.PUBLIC_PATH}assets/map/${currentDomain.href.replace(
          'https://',
          '',
        )}.svg`}
        width='950'
        height='665'
        type='image/svg+xml'
        aria-label='map'
      />
    </section>
  )
}
