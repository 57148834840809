import { TypeOf } from 'io-ts'
import React, { useState, useMemo, useCallback } from 'react'

import { InspirationItem } from '../../../../decoders/PageData'
import { selectInspirationItemsData } from '../../../../stores/selectors'

import { InspirationCard } from './InspirationCard'
import { ShowMoreButton } from './ShowMoreButton'

export type Props = Record<string, unknown>

export const InspirationCards = () => {
  const {
    inspirationItems,
    h1Title,
    h1Subtitle,
    h1Text,
    isDesktop,
    textSeeMore,
  } = selectInspirationItemsData()

  const [isMoreShown, setIsMoreShown] = useState(false)

  const renderRows = useCallback(
    (cards: TypeOf<typeof InspirationItem>[][]) => {
      return cards.map((cardsRow, idx) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} className='flex mb-4'>
            {cardsRow.map(({ imageName, text, href, wide }) => (
              <InspirationCard
                key={href}
                imageUrl={imageName}
                title={text}
                url={href}
                full={!isDesktop}
                wide={wide}
              />
            ))}
          </div>
        )
      })
    },
    [isDesktop],
  )

  const initiallyVisibleCards = useMemo(() => {
    return renderRows(inspirationItems[0])
  }, [renderRows, inspirationItems])

  const moreCards = useMemo(() => {
    return inspirationItems[1] && renderRows(inspirationItems[1])
  }, [renderRows, inspirationItems])

  const handleShowMoreClick = () => setIsMoreShown(true)

  return (
    <section className='bg-grey-lighter py-5'>
      <div className='container md:pl-5'>
        <div className='text-black-main mx-2'>
          <h2 className='font-bold md:font-light mt-2 text-2xl md:text-4xl'>
            {h1Title}
          </h2>
          <strong>{h1Subtitle}</strong>
          <p className='text-sm mt-0 text-black-main'>{h1Text}</p>
        </div>
        {initiallyVisibleCards}
        {!isMoreShown && inspirationItems[1] && (
          <div className='flex w-full mb-4 justify-center'>
            <div className='w-1/3 relative mx-2' />
            <ShowMoreButton
              onClick={handleShowMoreClick}
              className='w-1/3 relative mx-2'
            >
              {textSeeMore}
            </ShowMoreButton>
            <div className='w-1/3 relative mx-2' />
          </div>
        )}
        {isMoreShown && moreCards}
      </div>
    </section>
  )
}
