import * as t from 'io-ts'

export const AutoCompleteItem = t.type({
  value: t.string,
  mainPart: t.string,
  pathname: t.string,
  regionId: t.union([t.number, t.null]),
  offerId: t.union([t.string, t.null]),
})

export const AutoCompleteItems = t.array(AutoCompleteItem)

export const AutoCompleteResult = t.type({
  items: AutoCompleteItems,
  hasGoogleResults: t.boolean,
})
