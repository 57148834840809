import React from 'react'
import cx from 'classnames'

export type Props = {
  imageUrl: string
  title: string
  url: string
  wide?: boolean
  full?: boolean
}

const getImageUrl = (imageUrl: string) =>
  `https://sh-assets.holidu.com/home/images/${imageUrl}`

export const InspirationCard = ({
  imageUrl,
  title,
  url,
  wide,
  full,
}: Props) => {
  return (
    <div
      style={{
        // 1rem is here the total horizontal margin (mx-2)
        width: !full && wide ? 'calc( 66.66667% + 1rem )' : '',
        backgroundImage: `url('${getImageUrl(imageUrl)}')`,
      }}
      className={cx('bg-center bg-cover relative min-h-full mx-2', {
        'w-full': full,
        'w-1/3': !full && !wide,
      })}
    >
      <div
        className={cx({
          'pb-1/2': wide,
          'pb-full': !wide,
        })}
      />
      <div className='absolute top-0 bottom-0 left-0 right-0'>
        <a
          className='flex text-white w-full h-full items-center justify-center hover:bg-black-main hover:bg-opacity-20 transition-colors'
          href={url}
        >
          <span className='text-center text-2xl uppercase md:capitalize lg:text-4xl xl:text-5xl font-semibold md:font-extrabold inspiration-card-title'>
            {title}
          </span>
        </a>
      </div>
    </div>
  )
}
