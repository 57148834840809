import { useIntl } from '@shared/universal/utils/intl'
import cx from 'classnames'
import { HoliduLogoFull } from 'components/SVGs/HoliduLogoFull'
import React from 'react'

import { messages } from '../../../messages'
import { selectNavigationMenu } from '../../../stores/selectors'

import { NavigationBurgerHeader } from './NavigationBurgerHeader'
import { NavigationCollapse } from './NavigationCollapse'

export type Props = {
  isOpen: boolean
  onClickToClose: () => void
}

export const NavigationMobile = ({ isOpen, onClickToClose }: Props) => {
  const format = useIntl()

  const navigationMenu = selectNavigationMenu()

  return (
    <>
      <div
        role='presentation'
        id='wl-spainholiday-header-mobile-sidebar'
        className={cx(
          'Modal mobile-burger-overlay',
          isOpen && 'mobile-burger-overlay__open',
        )}
      >
        <div className='mobile-sidebar'>
          <NavigationBurgerHeader onClickToClose={onClickToClose} />
          <div className='mobile-navigation-button-container'>
            <NavigationCollapse submenu={navigationMenu.travellers.submenu}>
              {navigationMenu.travellers.text}
            </NavigationCollapse>
            {navigationMenu.homeOwners != null && (
              <NavigationCollapse submenu={navigationMenu.homeOwners.submenu}>
                {navigationMenu.homeOwners.text}
              </NavigationCollapse>
            )}
          </div>

          <div className='mobile-navigation-bottom-notice'>
            <span className='header__logo_text--small'>
              {format(messages.LOGO_IN_COOPERATION_WITH)}{' '}
            </span>
            <HoliduLogoFull className='mobile-navigation__logo' />
          </div>
        </div>
      </div>
      <div
        aria-hidden='true'
        id='wl-spainholiday-header-navigation-mobile'
        className={cx(isOpen && 'mobile-portal__open', 'mobile-portal')}
        role='presentation'
        onMouseDown={onClickToClose}
      />
    </>
  )
}
