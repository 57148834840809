import React from 'react'

import { selectAboutUs } from '../../../stores/selectors'

export const AboutUs = () => {
  const aboutUs = selectAboutUs()

  return (
    <section className='bg-grey-lighter py-16'>
      <div className='container bg-white flex'>
        <div
          className='hidden lg:block lg:w-1/3 xl:w-1/4 bg-no-repeat bg-cover'
          style={{
            backgroundImage: `url('${process.env.PUBLIC_PATH}assets/images/about-us.jpeg')`,
          }}
        />
        <div className='w-full lg:w-2/3 xl:w-3/4 pt-5 pb-20 text-grey-dark px-6 about-us-content text-sm'>
          <div dangerouslySetInnerHTML={{ __html: aboutUs.text }} />
        </div>
      </div>
    </section>
  )
}
