import React, { useCallback, useState, MouseEvent } from 'react'
import cx from 'classnames'

import { ChevronRight } from '../SVGs/ChevronRight'

export type Props = {
  toggle: React.ReactNode
  children: React.ReactNode
  toggleClassName?: string
}

export const Collapse = ({ children, toggle, toggleClassName }: Props) => {
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback((ev: MouseEvent) => {
    ev.preventDefault()
    setOpen(open => !open)
  }, [])

  return (
    <div className='wl-spainholiday-header-collapse mb-2'>
      <div
        className={toggleClassName}
        onClick={handleToggle}
        aria-hidden='true'
      >
        {toggle}
        <ChevronRight
          className={`mobile-burger__chevron transform ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </div>
      <div
        className={cx('wl-spainholiday-header-collapse-menu', {
          hidden: !open,
        })}
      >
        {children}
      </div>
    </div>
  )
}
