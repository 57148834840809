import React from 'react'

import { selectNavigationMenu } from '../../../stores/selectors'
import { SiteSettings } from '../SiteSettings'

import { NavigationDropdown } from './NavigationDropdown'

export const Navigation = () => {
  const navigationMenu = selectNavigationMenu()

  return (
    <>
      <nav role='navigation' className='header-flex'>
        <SiteSettings />
        <ul className='header-flex header-text-black-ultra header__navigation_ul'>
          <NavigationDropdown submenu={navigationMenu.travellers.submenu}>
            {navigationMenu.travellers.text}
          </NavigationDropdown>
          {navigationMenu.homeOwners != null && (
            <NavigationDropdown submenu={navigationMenu.homeOwners.submenu}>
              {navigationMenu.homeOwners.text}
            </NavigationDropdown>
          )}
        </ul>
      </nav>
    </>
  )
}
