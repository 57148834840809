import React from 'react'

export type Props = {
  children: React.ReactNode
}

export const UspItem = ({ children }: Props) => {
  return (
    <div
      className='font-semibold text-sm w-full text-center text-white 
      md:w-home-usp-item md:mx-5 lg:mx-14 flex flex-col items-center'
    >
      {children}
    </div>
  )
}
