import React, { ReactNode, useMemo } from 'react'
import { IntlContext } from '@shared/universal/utils/intl'

import { selectLocale, selectMessageData } from '../../stores/selectors'

export const IntlContextProvider = ({ children }: { children: ReactNode }) => {
  const messageData = selectMessageData()
  const locale = selectLocale()
  const value = useMemo(() => ({ messageData, locale }), [messageData, locale])

  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>
}
