import cx from 'classnames'
import { TypeOf } from 'io-ts'
import React from 'react'

import { useIntl } from '../../utils/intl'
import { DateInput, Props as DateInputProps } from '../DateInput'
import { PersonPicker, Props as PersonPickerProps } from '../PersonPicker'
import { SearchBarButton } from '../SearchBarButton'
import { messages } from '../messages'

import { SearchField } from './SearchField'
import {
  AutoCompleteItem,
  AutoCompleteItems,
} from './decoders/AutoCompleteDecoder'

export type Props = {
  onSearchButtonClick: React.MouseEventHandler<HTMLButtonElement>
  value: string
  autocompleteItems: TypeOf<typeof AutoCompleteItems> | null | undefined
  hasGoogleResults?: boolean
  dateInputProps: DateInputProps
  personPickerProps: PersonPickerProps
  isSearchFieldFocused: boolean
  handleSearchFieldFocus: () => void
  handleSearchFieldBlur: () => void
  onAutocompleteItemClick?: (item: TypeOf<typeof AutoCompleteItem>) => void
  onChange: (value: string) => void
  onClear: React.MouseEventHandler<HTMLButtonElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onCheckboxChange: React.ChangeEventHandler<HTMLInputElement>
  isCheckboxChecked: boolean
}

export const SearchBar = ({
  onSearchButtonClick,
  value,
  autocompleteItems,
  hasGoogleResults,
  dateInputProps,
  personPickerProps,
  isSearchFieldFocused,
  handleSearchFieldFocus,
  handleSearchFieldBlur,
  onAutocompleteItemClick,
  onClear,
  onSubmit,
  onChange,
  onCheckboxChange,
  isCheckboxChecked,
}: Props) => {
  const format = useIntl()

  return (
    <div className='flex w-full flex-col items-center'>
      <div className='w-full mx-2 sm:w-4/6 md:w-4/5 lg:w-10/12 p-2 max-w-5xl bg-white bg-opacity-60 rounded-md lg:flex relative lg:static'>
        <div className='flex-1'>
          <SearchField
            isFocused={isSearchFieldFocused}
            value={value}
            autocompleteItems={autocompleteItems}
            hasGoogleResults={hasGoogleResults}
            onAutocompleteItemClick={onAutocompleteItemClick}
            onClear={onClear}
            onSubmit={onSubmit}
            onChange={onChange}
            onFocus={handleSearchFieldFocus}
            onBlur={handleSearchFieldBlur}
          />
        </div>
        <div className='md:flex lg:ml-2 mt-2 lg:mt-0'>
          <div
            className={cx('flex-1 min-h-search-bar-row', {
              'block md:flex': !isSearchFieldFocused,
              'md:hidden': isSearchFieldFocused,
            })}
          >
            <div className='flex flex-1 min-h-search-bar-row mb-2 md:mb-0'>
              <DateInput {...dateInputProps} className='flex-1' />
            </div>
            <div className='flex min-h-search-bar-row md:ml-2'>
              <PersonPicker {...personPickerProps} className='flex-1' />
            </div>
          </div>
          <div>
            <SearchBarButton
              className='w-full md:w-44 lg:w-40 py-2 md:py-0 mt-2 md:mt-0 md:ml-2 h-full'
              form='searchBarForm'
              type='submit'
              onClick={onSearchButtonClick}
            >
              {format(messages.DO_SEARCH)}
            </SearchBarButton>
          </div>
        </div>
      </div>
      <div className='w-full mx-2 sm:w-4/6 md:w-4/5 lg:w-10/12 p-2 max-w-5xl rounded-md lg:flex relative lg:static'>
        <div className='w-max flex bg-white px-2 py-[6px] rounded-md items-center'>
          <span className='font-semibold text-sm'>
            {format(messages.COMPARE_PRICE)}:
          </span>
          <div className='flex ml-2 items-center'>
            <input
              className='w-5 h-5 cursor-pointer'
              id='price-compare'
              type='checkbox'
              checked={isCheckboxChecked}
              onChange={onCheckboxChange}
            />
            <label
              className='ml-2 cursor-pointer text-sm'
              htmlFor='price-compare'
            >
              Holidu
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
