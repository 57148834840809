import { getButtonClassnames } from '@shared/universal/utils/buttonUtils'
import React from 'react'

import {
  selectAvailableCurrencies,
  selectCurrency,
  selectCurrentDomain,
  selectUrls,
} from '../../stores/selectors'
import { generateUrl } from '../../utils/url'

export const SiteSettings = () => {
  const currentDomain = selectCurrentDomain()
  const urls = selectUrls()
  const availableCurrencies = selectAvailableCurrencies()
  const currency = selectCurrency()

  const handleCurrencyChange = (code: string) => (ev: React.MouseEvent) => {
    ev.preventDefault()
    window.location.href = generateUrl(window.location.href, code)
  }

  return (
    <nav className='flex'>
      <div className='group settings-dropdown relative'>
        <div className='group-hover:bg-grey-light px-2 py-1 rounded-md flex items-center transition-colors'>
          <a
            className='header__navigation_link'
            href='/'
            onClick={ev => ev.preventDefault()}
          >
            <span className='mr-5'>
              {currency || availableCurrencies[0].code}
            </span>
            <div className='flex items-center'>
              <img
                alt=''
                width={27}
                height={17}
                className='mr-2'
                src={`${process.env.PUBLIC_PATH}assets/images/flags/${currentDomain.languageCode}.svg`}
              />
              {currentDomain.languageName}
            </div>
          </a>
        </div>
        <div className='header__dropdown_menu-wrapper w-site-settings flex py-4'>
          <div className='w-1/2 border-0 border-r border-solid border-grey-lighter header-px-5'>
            <div className='text-xl font-light mb-4'>Currency</div>
            <ul className='site-settings-list capitalize'>
              {availableCurrencies.map(({ code }) => (
                <li key={code}>
                  <button
                    type='button'
                    data-currency-code={code}
                    onClick={handleCurrencyChange(code)}
                    className={getButtonClassnames(
                      false,
                      'border-0 outline-none p-0',
                      'wl-spainholiday-header-currency-link dropdown-link',
                      (currency || availableCurrencies[0].code) === code
                        ? 'font-semibold'
                        : 'font-normal',
                    )}
                  >
                    {code}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className='w-1/2 header-px-5'>
            <div className='text-xl font-light mb-4'>Languages</div>
            <ul className='site-settings-list capitalize'>
              {urls.map(
                ({
                  siteId,
                  href,
                  languageCode,
                  languageName,
                  isCurrentSite,
                }) => (
                  <li key={siteId}>
                    <a
                      href={generateUrl(href)}
                      hrefLang={languageCode}
                      className={`dropdown-link ${
                        isCurrentSite ? 'font-semibold' : 'font-normal'
                      }`}
                    >
                      <img
                        alt=''
                        width={27}
                        height={17}
                        className='mr-2'
                        src={`${process.env.PUBLIC_PATH}assets/images/flags/${languageCode}.svg`}
                      />{' '}
                      {languageName}
                    </a>
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
