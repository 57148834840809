import React from 'react'
import cx from 'classnames'

import { ModifierButton } from './ModifierButton'

export type Props = {
  className?: string
  name: string
  value: number
  min?: number
  onChange: (update: number) => void
}

export const Field = ({ className, name, value, min = 0, onChange }: Props) => {
  return (
    <div
      className={cx(
        'flex flex-1 justify-between items-center pb-3 px-3',
        className,
      )}
    >
      <div>{name}</div>
      <div className='flex items-center'>
        <ModifierButton disabled={value <= min} isMinus onClick={onChange} />
        <div className='font-bold text-xl mx-2 w-6 text-center'>{value}</div>
        <ModifierButton onClick={onChange} />
      </div>
    </div>
  )
}
