import cx from 'classnames'
import React, { useCallback } from 'react'
import { useIntl } from '@shared/universal/utils/intl'

import {
  selectCurrentDomain,
  selectFooterData,
  selectUrls,
} from '../../stores/selectors'
import { generateUrl } from '../../utils/url'
import { messages } from '../../messages'

import { FooterLink } from './FooterLink'
import { FooterLinks } from './FooterLinks'
import { FooterTitle } from './FooterTitle'

const cookiePreferenceButtonId = 'cookie-preference-button'
export const cookiePreferenceButtonEventListener = `
document.addEventListener("DOMContentLoaded", () => {
  const button = document.getElementById("${cookiePreferenceButtonId}");
  if (button) {
    button.addEventListener("click", () => {
      const customEvent = new CustomEvent("showCookiePreferenceBanner", {
        detail: { open: true },
      });
      window.dispatchEvent(customEvent);
    });
  }
});
`

const currentYear = new Date().getFullYear()

export const Footer = () => {
  const format = useIntl()
  const urls = selectUrls()
  const currentDomain = selectCurrentDomain()
  const {
    footerMenu2,
    footerMenu2Title,
    footerMenu3,
    footerMenu3Title,
    footerMenuLanguagesTitle,
    copyrightText,
  } = selectFooterData()

  const updatedCopyrightText = copyrightText.replace(
    '2021',
    currentYear.toString(),
  )

  const changeLanguage = useCallback((href: string) => {
    document.location.href = generateUrl(href)
  }, [])

  const showCookiePreferenceBanner = useCallback(() => {
    const customEvent = new CustomEvent('showCookiePreferenceBanner', {
      detail: { open: true },
    })
    window.dispatchEvent(customEvent)
  }, [])

  return (
    <footer className='footer shadow-inner footer__wrapper'>
      <div className='container'>
        <div className='flex justify-between flex-wrap'>
          <FooterLinks
            className='w-1/2 lg:w-1/4 flex-shrink-0 mb-8 lg:mb-0'
            title={footerMenu2Title}
          >
            {footerMenu2.map(({ text, href }) => (
              <FooterLink key={text} href={href}>
                {text}
              </FooterLink>
            ))}
            <button
              id={cookiePreferenceButtonId}
              className='text-white footer-text-sm outline-none p-0 border-0 cursor-pointer'
              onClick={showCookiePreferenceBanner}
              type='button'
            >
              {format(messages.COOKIES_SETTINGS_FOOTER)}
            </button>
          </FooterLinks>
          <FooterLinks
            className='w-1/2 lg:w-1/4 flex-shrink-0'
            title={footerMenu3Title}
          >
            {footerMenu3.map(({ text, href }) => (
              <FooterLink key={text} href={href}>
                {text}
              </FooterLink>
            ))}
          </FooterLinks>
          <div className='w-1/2 lg:w-1/4 flex-shrink-0'>
            <div className='footer-mt-7'>
              <FooterTitle>{footerMenuLanguagesTitle}</FooterTitle>
              <select
                id='wl-spainholiday-footer-lang-select'
                onChange={ev => changeLanguage(ev.target.value)}
                value={currentDomain.href}
                className='w-full border-2 border-solid border-white text-white outline-none footer-py-2 footer-px-4 capitalize footer-text-md'
              >
                {urls.map(({ href, isCurrentSite, languageName }) => (
                  <option
                    className={cx(
                      isCurrentSite ? 'text-grey-dark' : 'text-black-ultra',
                    )}
                    value={href}
                    disabled={isCurrentSite}
                    key={href}
                  >
                    {languageName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='text-center text-white footer-text-sm'>
          <p className='footer-mt-7 mb-0'> {updatedCopyrightText} </p>
        </div>
      </div>
    </footer>
  )
}
