import { Close } from 'components/SVGs/Close'
import { SHLogo } from 'components/SVGs/SHLogo'
import React from 'react'

type Props = {
  onClickToClose: () => void
}

export const NavigationBurgerHeader = ({ onClickToClose }: Props) => {
  return (
    <div className='mobile-navigation-header'>
      <a href='/'>
        <SHLogo />
      </a>

      <button
        id='wl-spainholiday-header-navigation-mobile-close-button'
        className='mobile-navigation-close-button'
        type='button'
        onClick={onClickToClose}
      >
        <Close className='w-6 h-6' />
      </button>
    </div>
  )
}
