import React from 'react'

import { Minus } from '../SVGs/Minus'
import { Plus } from '../SVGs/Plus'
import { getButtonClassnames } from '../../utils/buttonUtils'

export type Props = {
  className?: string
  isMinus?: boolean
  disabled?: boolean
  onClick: (update: number) => void
}

export const ModifierButton = ({
  className,
  disabled,
  isMinus,
  onClick,
}: Props) => {
  const Icon = isMinus ? Minus : Plus

  return (
    <button
      disabled={disabled}
      type='button'
      className={getButtonClassnames(
        disabled,
        'rounded-full w-8 h-8 flex items-center justify-center border border-solid',
        {
          'bg-grey-dark text-white border-transparent': !disabled,
          'bg-white text-grey-dark border-grey-light': disabled,
        },
        className,
      )}
      onClick={() => onClick(isMinus ? -1 : 1)}
    >
      <Icon className='text-white w-5 h-5' />
    </button>
  )
}
