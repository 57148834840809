import React, { ButtonHTMLAttributes } from 'react'
import cx from 'classnames'

export type Props = ButtonHTMLAttributes<HTMLButtonElement>

export const MonthController = ({ className, children, ...others }: Props) => {
  return (
    <button
      type='button'
      className={cx(
        'absolute appearance-none border-none outline-none text-white cursor-pointer w-10 h-10 rounded-full bg-grey-dark active:bg-black-main',
        className,
      )}
      {...others}
    >
      {children}
    </button>
  )
}
