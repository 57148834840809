import React from 'react'

export type Props = {
  children: React.ReactNode
}

export const FooterTitle = ({ children }: Props) => (
  <span className='footer-mb-2 mt-0 font-light footer-text-xl text-white'>
    {children}
  </span>
)
