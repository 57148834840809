import { createAnalytics } from '@holidu/lib/universal/analytics'
import { INTERNAL_V5_URL } from 'consts/universal'
import { useEffect, useMemo, useState } from 'react'
import {
  selectCurrency,
  selectDomainConfig,
  selectLocale,
  selectUserId,
  selectUuid,
} from 'stores/selectors'

export const useAnalytics = () => {
  const uuid = selectUuid()
  const userId = selectUserId()
  const locale = selectLocale()
  const currency = selectCurrency()
  const domainConfig = selectDomainConfig()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => setIsMounted(true), [])

  return useMemo(() => {
    if (isMounted) {
      const setupAnalytics = createAnalytics({
        internalUrl: INTERNAL_V5_URL.replace(
          '{domainId}',
          domainConfig.domainId.toString(),
        )
          .replace('{locale}', locale)
          .replace('{currency}', currency),
        googleAnalyticsKey: domainConfig.googleAnalyticsKey || '',
      })

      return setupAnalytics({
        ga: domainConfig.googleAnalyticsKey || '',
        userId,
        uuid,
        locale,
        currency,
        isUserSignedIn: false,
        clientVersion: window.appVersion,
        userAgent: window.navigator.userAgent,
      })
    }

    return undefined
  }, [
    isMounted,
    currency,
    domainConfig.googleAnalyticsKey,
    domainConfig.domainId,
    locale,
    userId,
    uuid,
  ])
}
