import { format } from 'date-fns'

export type SearchParams = {
  searchTerm?: string
  checkin?: Date
  checkout?: Date
  adults?: number
  kids?: number
  bedrooms?: number
  kidsAges?: (number | undefined)[]
}

export const buildSearchUrl = ({
  searchTerm,
  checkin,
  checkout,
  adults,
  kids,
  bedrooms,
  kidsAges,
}: SearchParams) => {
  const filteredKidsAges = kidsAges?.filter(age => age !== undefined)

  const url = new URL(location.href)
  url.pathname = `/s/${searchTerm}`

  if (checkin) url.searchParams.set('checkin', format(checkin, 'yyyy-MM-dd'))
  if (checkout) url.searchParams.set('checkout', format(checkout, 'yyyy-MM-dd'))
  if (adults) url.searchParams.set('adults', String(adults))
  if (kids) url.searchParams.set('children', String(kids))
  if (bedrooms) url.searchParams.set('bedrooms', String(bedrooms))
  if (filteredKidsAges?.length)
    url.searchParams.set('childrenAges', filteredKidsAges.join(','))

  return url.href
}

export const buildHoliduSearchUrl = (
  searchUrl: string,
  matchedHost: string,
) => {
  const url = new URL(searchUrl)

  url.host = matchedHost

  return url.href
}
