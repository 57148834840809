import React from 'react'
import { ChevronRight } from 'components/SVGs/ChevronRight'

export type Props = {
  title: string
  url: string
}

export const Item = ({ title, url }: Props) => (
  <li className='overflow-ellipsis overflow-hidden whitespace-nowrap mb-1'>
    <ChevronRight className='text-primary mr-2 w-3 h-3 transform -rotate-90' />
    <a
      href={url}
      className='text-grey-dark hover:text-primary transition-colors text-sm'
    >
      {title}
    </a>
  </li>
)
