import { PullstateProvider } from 'pullstate'
import React from 'react'
import { hydrate } from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { initSentry } from '@holidu/lib/client/sentry'
import * as Sentry from '@sentry/react'
import { isLive } from 'consts'

import { PullstateCore } from '../../stores/PullstateCore'

import { App } from './App'

if (isLive) {
  initSentry(
    'SPAIN_HOLIDAY_CLIENT',
    process.env.RAZZLE_BUILD_ENV === 'live' ? 'live' : 'feature',
    `spain-holiday@v${process.env.RAZZLE_VERSION!}`,
  )
}

const pullstateInstance = PullstateCore.instantiate({
  ssr: false,
  hydrateSnapshot: window.__PULLSTATE__,
})

hydrate(
  <Sentry.ErrorBoundary fallback={<p>Ooooooooops</p>}>
    <HelmetProvider>
      <PullstateProvider instance={pullstateInstance}>
        <App />
      </PullstateProvider>
    </HelmetProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
