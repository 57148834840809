export const messages = {
  APPLY: { id: 'APPLY', defaultValue: 'Apply' },
  DO_SEARCH: { id: 'DO_SEARCH', defaultValue: 'Search' },
  COMPARE_PRICE: { id: 'COMPARE_PRICE', defaultValue: 'Compare price' },
  DESTINATION: { id: 'DESTINATION', defaultValue: 'Destination' },
  INPUT_DESTINATION: {
    id: 'INPUT_DESTINATION',
    defaultValue: 'Where do you want to go?',
  },
  CHECK_IN: { id: 'CHECK_IN', defaultValue: 'Check-in' },
  CHECK_OUT: { id: 'CHECK_OUT', defaultValue: 'Check-out' },
  DO_SELECT: { id: 'DO_SELECT', defaultValue: 'Select' },
  PERSON: { id: 'PERSON', defaultValue: 'Guest' },
  PERSONS: { id: 'PERSONS', defaultValue: 'Guests' },
  ADULTS: { id: 'ADULTS', defaultValue: 'Adults' },
  ADULT: { id: 'ADULT', defaultValue: 'Adult' },
  KIDS: { id: 'CHILDREN', defaultValue: 'Children' },
  CHILD: { id: 'CHILD', defaultValue: 'Child' },
  SLEEPING_ROOMS: { id: 'SLEEPING_ROOMS', defaultValue: 'Bedrooms' },
  SLEEPING_ROOM: { id: 'SLEEPING_ROOM', defaultValue: 'Bedroom' },
  AGE: { id: 'AGE', defaultValue: 'Age' },
  YEARS: { id: 'YEARS', defaultValue: 'years' },
}
