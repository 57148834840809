import React from 'react'

export type Props = {
  children: React.ReactNode
  className?: string
  title?: string
}

export const List = ({ children, className, title }: Props) => (
  <div className={className}>
    {title && <h3 className='font-light mt-0'>{title}</h3>}
    <ul className='list-none px-0'>{children}</ul>
  </div>
)
