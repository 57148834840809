import { logError } from '@holidu/lib/universal/universalLogger'
import { fetchJSONAndDecode } from '@holidu/lib/universal/fetch'
import * as E from 'fp-ts/Either'
import { TypeOf } from 'io-ts'
import { useEffect, useState } from 'react'

import {
  AutoCompleteItems,
  AutoCompleteResult,
} from './decoders/AutoCompleteDecoder'

const API_V6_SEARCH_AUTOCOMPLETE_PATH = '/old/rest/v6/search/autocomplete'

export const useAutocomplete = (
  inputValue: string,
  locale: string,
  currency: string,
  domainId: number,
) => {
  const [items, setItems] = useState<TypeOf<typeof AutoCompleteItems>>([])
  const [hasGoogleResults, setHasGoogleResults] = useState(false)

  useEffect(() => {
    const url = new URL(
      `${API_V6_SEARCH_AUTOCOMPLETE_PATH}/${inputValue}`,
      'https://api.holidu.com',
    )

    url.search = new URLSearchParams({
      locale,
      currency,
      domainId: domainId.toString(),
    }).toString()

    const fetchAutocomplete = async () => {
      const responseE = await fetchJSONAndDecode(
        url.href,
        {},
        AutoCompleteResult,
      )()

      if (E.isRight(responseE)) {
        setHasGoogleResults(responseE.right.hasGoogleResults)
        setItems(responseE.right.items)
      } else {
        logError(responseE.left)
      }
    }

    fetchAutocomplete()
  }, [inputValue, locale, currency, domainId])

  return { items, hasGoogleResults }
}
