import cx from 'classnames'
import React from 'react'

export type Props = {
  title: React.ReactNode
  children: React.ReactNode
  className?: string
}

export const LinkList = ({ title, children, className }: Props) => (
  <section className={cx('bg-white text-black-main', className)}>
    <div className='container'>
      {title}
      <div className='flex flex-wrap'>{children}</div>
    </div>
  </section>
)
