import { useDateInput } from '@shared/universal/components/DateInput/hooks'
import { usePersonPicker } from '@shared/universal/components/PersonPicker/hooks'
import { SearchBar } from '@shared/universal/components/SearchBar'
import { AutoCompleteItem } from '@shared/universal/components/SearchBar/decoders/AutoCompleteDecoder'
import { useAutocomplete } from '@shared/universal/components/SearchBar/hooks'
import { InternalPageEventData } from '@holidu/lib/universal/analytics/types'
import {
  SearchParams,
  buildHoliduSearchUrl,
  buildSearchUrl,
} from '@shared/universal/components/SearchBar/utils'
import { bracketsToHtml } from '@shared/universal/utils/stringUtils'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { IntlContextProvider } from 'components/Intl/IntlContextProvider'
import { useAnalytics } from 'components/hooks/useAnalytics'
import * as t from 'io-ts'
import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  selectCurrency,
  selectDomainConfig,
  selectLocale,
  selectMatchedHost,
  selectSliderTitle,
} from 'stores/selectors'
import 'styles/footer.css'
import 'styles/header.css'
import 'styles/index.css'
import 'styles/tailwind.css'

import { AboutUs } from './components/AboutUs'
import { Head } from './components/Head'
import { InspirationCards } from './components/InspirationCards'
import { PopularDestinations } from './components/LinkLists/PopularDestinations'
import { ListWithUsLink } from './components/ListWithUsLink'
import { SpainMap } from './components/SpainMap'
import { Usp } from './components/Usp'

const pageEventData: InternalPageEventData = {
  eventType: 'page',
  abTests: [],
  customEventData: { pageType: 'HOME_PAGE' },
}

export const App = () => {
  const [isSearchFieldFocused, setIsSearchFieldFocused] = useState(false)
  const [isComparePriceChecked, setIsComparePriceChecked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const sliderTitle = selectSliderTitle()
  const locale = selectLocale()
  const currency = selectCurrency()
  const domainConfig = selectDomainConfig()
  const analytics = useAnalytics()
  const matchedHost = selectMatchedHost()

  useEffect(() => {
    analytics?.trackInternalBrowserEvent(pageEventData)()
    analytics?.trackGoogleAnalytics('send', 'pageview')
  }, [analytics])

  const { items, hasGoogleResults } = useAutocomplete(
    searchTerm,
    locale,
    currency,
    domainConfig.domainId,
  )

  const dateInputHook = useDateInput({
    handleDateSelection: (checkin, checkout) => {
      if (searchTerm.trim()) {
        doSearch({ checkin, checkout })
      }
    },
  })
  const personPickerHook = usePersonPicker()

  const handleSearch = (event?: MouseEvent | FormEvent) => {
    event?.preventDefault()
    doSearch({})
  }

  const doSearch = (params: SearchParams) => {
    const searchUrl = buildSearchUrl({
      searchTerm,
      checkin: dateInputHook.checkinDay,
      checkout: dateInputHook.checkoutDay,
      adults: personPickerHook.adults,
      bedrooms: personPickerHook.bedrooms,
      kids: personPickerHook.kids,
      kidsAges: personPickerHook.kidsAges,
      ...params,
    })

    if (isComparePriceChecked) {
      window.open(searchUrl)

      const holiduUrl = buildHoliduSearchUrl(searchUrl, matchedHost)

      window.location.href = holiduUrl

      return
    }

    window.location.href = searchUrl
  }

  const handleSearchFieldFocus = () => setIsSearchFieldFocused(true)
  const handleSearchFieldBlur = () => setIsSearchFieldFocused(false)
  const handleAutocompleteItemClick = (
    item: t.TypeOf<typeof AutoCompleteItem>,
  ) => {
    if (dateInputHook.checkinDay && dateInputHook.checkoutDay) {
      doSearch({ searchTerm: item.value })
    } else {
      dateInputHook.removeEventListeners()
      setIsSearchFieldFocused(false)
      dateInputHook.handleCheckinInputClick()
    }
  }

  const handleSearchTermChange = useCallback<(value: string) => void>(
    value => setSearchTerm(value),
    [setSearchTerm],
  )

  const handleSearchTermClear = useCallback(
    () => setSearchTerm(''),
    [setSearchTerm],
  )

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    analytics?.trackInternalBrowserEvent({
      eventType: 'generic',
      genericData: {},
      abTests: [],
      customEventData: {
        category: 'USER_INTERACTION',
        action: `compare-price-${event.target.checked ? 'check' : 'uncheck'}`,
        pageType: 'HOME_PAGE',
      },
    })()

    setIsComparePriceChecked(event.target.checked)
  }

  return (
    <IntlContextProvider>
      <Head />
      <Header />
      <section className='relative h-home-section-mobile md:h-home-section'>
        <div className='absolute left-0 bottom-0 md:bottom-0 top-0 right-0 flex bg-grey-light'>
          <video className='w-full h-full object-cover' loop muted autoPlay>
            <source
              src={`${process.env.PUBLIC_PATH}assets/videos/frontpage-video.mp4`}
              type='video/mp4'
            />
          </video>
          <div className='absolute z-search-bar left-0 bottom-0 top-0 right-0 pt-32 md:pt-44'>
            <div className='w-full h-full flex flex-col items-stretch'>
              <h1
                className='m-0 hidden text-7xl md:inline text-center text-white home-title mb-5 font-normal'
                dangerouslySetInnerHTML={{
                  __html: bracketsToHtml(sliderTitle),
                }}
              />
              <SearchBar
                isSearchFieldFocused={isSearchFieldFocused}
                handleSearchFieldBlur={handleSearchFieldBlur}
                handleSearchFieldFocus={handleSearchFieldFocus}
                personPickerProps={personPickerHook}
                dateInputProps={dateInputHook}
                value={searchTerm}
                hasGoogleResults={hasGoogleResults}
                autocompleteItems={items}
                onAutocompleteItemClick={handleAutocompleteItemClick}
                onChange={handleSearchTermChange}
                onClear={handleSearchTermClear}
                onSubmit={handleSearch}
                onSearchButtonClick={handleSearch}
                onCheckboxChange={handleCheckboxChange}
                isCheckboxChecked={isComparePriceChecked}
              />
            </div>
          </div>
        </div>
      </section>
      <Usp />
      <SpainMap />
      <InspirationCards />
      <PopularDestinations />
      <ListWithUsLink />
      <AboutUs />
      <Footer />
    </IntlContextProvider>
  )
}
