import React from 'react'
import cx from 'classnames'

import {
  SearchBarDropdownButton,
  Props as SearchBarDropdownButtonProps,
} from '../SearchBarDropdown/SearchBarDropdownButton'
import { messages } from '../messages'
import { useIntl } from '../../utils/intl'

export type Props = {
  placeholder: string
  date?: Date
} & SearchBarDropdownButtonProps

export const DateInputButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ placeholder, date, className, children, isActive, ...others }, ref) => {
    const format = useIntl()
    const isPlaceholderUp = Boolean(date || isActive)
    // eslint-disable-next-line no-nested-ternary
    const formattedData = date
      ? date.toLocaleDateString()
      : isActive
      ? format(messages.DO_SELECT)
      : ''

    return (
      <SearchBarDropdownButton
        ref={ref}
        isActive={isActive}
        className={cx(
          'relative py-1 w-1/2 lg:w-32 min-h-search-bar-row',
          className,
        )}
        {...others}
      >
        <div
          className={cx('absolute flex items-center', {
            'top-2 text-xxs uppercase': isPlaceholderUp,
            'top-0 bottom-0': !isPlaceholderUp,
          })}
        >
          {placeholder}
        </div>
        <div
          className={cx('font-bold h-full flex items-end pb-0.5', {
            'text-calendar-checkin-checkout-day': !date && isActive,
          })}
        >
          {formattedData}
        </div>
      </SearchBarDropdownButton>
    )
  },
)

DateInputButton.displayName = 'DateInputButton'
