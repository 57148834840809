import React, { useRef, HTMLAttributes, useState } from 'react'
import cx from 'classnames'

import { SearchBarDropdown } from '../SearchBarDropdown/SearchBarDropdown'
import { useOnClickOutside } from '../hooks/useOutsideClick'
import { SearchBarButton } from '../SearchBarButton'
import { messages } from '../messages'
import { useIntl } from '../../utils/intl'

import { PersonPickerButton } from './PersonPickerButton'
import { usePersonPicker } from './hooks'
import { Field } from './Field'
import { AgeSelects } from './AgeSelects'

export type Props = ReturnType<typeof usePersonPicker> &
  HTMLAttributes<HTMLDivElement>

export const PersonPicker = ({
  className,
  adults,
  setAdults,
  kids,
  setKids,
  bedrooms,
  setBedrooms,
  kidsAges,
  setKidsAge,
}: Props) => {
  const format = useIntl()
  const menuRef = useRef<HTMLDivElement>(null)
  const toggleRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside([menuRef, toggleRef], () => setIsMenuVisible(false))

  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const buttons = (
    <>
      <PersonPickerButton
        ref={toggleRef}
        onClick={() => setIsMenuVisible(open => !open)}
        isActive={isMenuVisible}
        guests={adults + kids}
        bedrooms={bedrooms}
      />
    </>
  )

  const handleApplyClick = () => setIsMenuVisible(false)

  return (
    <SearchBarDropdown
      buttons={buttons}
      isMenuOpen={isMenuVisible}
      className={cx(className, 'relative')}
    >
      <div
        ref={menuRef}
        className={cx(
          'bg-white border border-solid border-grey-light md:w-person-picker-desktop',
          {
            'search-bar-menu-triangle triangle-middle': isMenuVisible,
          },
        )}
      >
        <Field
          className='pt-5'
          name={format(messages.ADULTS)}
          value={adults}
          min={1}
          onChange={update => setAdults(adults => adults + update)}
        />
        <Field
          name={format(messages.KIDS)}
          value={kids}
          onChange={update => setKids(kids => kids + update)}
        />
        {kidsAges.length > 0 && (
          <div className='px-3 py-3 mb-3 bg-grey-lighter'>
            <AgeSelects
              className='w-full'
              ages={kidsAges}
              onChange={setKidsAge}
            />
          </div>
        )}
        <Field
          className='pb-5'
          name={format(messages.SLEEPING_ROOMS)}
          value={bedrooms}
          onChange={update => setBedrooms(bedrooms => bedrooms + update)}
        />
        <div className='px-3 pb-3 hidden md:block'>
          <SearchBarButton className='w-full py-2' onClick={handleApplyClick}>
            {format(messages.APPLY)}
          </SearchBarButton>
        </div>
      </div>
    </SearchBarDropdown>
  )
}
