import React from 'react'
import { selectHomeUsps } from 'stores/selectors'
import { Chat } from 'components/SVGs/Chat'
import { Star } from 'components/SVGs/Star'
import { ThumbUp } from 'components/SVGs/ThumbUp'

import { UspIcon } from './UspIcon'
import { UspItem } from './UspItem'

export type Props = {
  children: React.ReactNode
}

export const Usp = () => {
  const usps = selectHomeUsps()

  return (
    <div className='md:flex justify-center relative md:pb-32'>
      <div className='md:absolute bg-primary p-3 md:flex md:-top-20 md:rounded z-home-usps'>
        {usps[0] && (
          <UspItem>
            <UspIcon>
              <ThumbUp />
            </UspIcon>
            <span>{usps[0].text}</span>
          </UspItem>
        )}
        {usps[1] && (
          <UspItem>
            <UspIcon>
              <Chat />
            </UspIcon>
            <span>{usps[1].text}</span>
          </UspItem>
        )}
        {usps[2] && (
          <UspItem>
            <UspIcon>
              <Star />
            </UspIcon>
            <span>{usps[2].text}</span>
          </UspItem>
        )}
      </div>
    </div>
  )
}
