import React from 'react'
import { Helmet } from 'react-helmet-async'

import { selectCurrentDomain, selectPageData } from '../../../stores/selectors'
import { CookieConsentScript } from '../../../components/CookieConsentScript/CookieConsentScript'

const currentYear = new Date().getFullYear()
const nextYear = currentYear + 1

export const Head = () => {
  const { title, description, urls, images, canonicalUrl } = selectPageData()
  const currentDomain = selectCurrentDomain()

  const updatedTitle = title
    .replace('2022', nextYear.toString())
    .replace('2021', currentYear.toString())

  return (
    <Helmet>
      <title>{updatedTitle}</title>
      <meta name='title' content={updatedTitle} />
      <meta name='description' content={description} />
      <meta property='og:title' content={updatedTitle} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={images[0]} />
      <meta property='og:url' content={canonicalUrl} />
      <meta name='twitter:title' content={updatedTitle} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={images[0]} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='robots' content='index, follow' />
      <link rel='canonical' href={currentDomain.href} />
      {urls
        .filter(url => !url.isCurrentSite)
        .map(url => {
          return (
            <link
              key={url.languageCode}
              rel='alternate'
              hrefLang={url.languageCode}
              href={url.href}
            />
          )
        })}
      <script type='text/javascript' async>
        {CookieConsentScript}
      </script>
    </Helmet>
  )
}
