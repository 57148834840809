// WARNING: every change in this file (and used files) should also be taken into account in the static version that is served at /static/header
import { useIntl } from '@shared/universal/utils/intl'
import cx from 'classnames'
import { Bars } from 'components/SVGs/Bars'
import { HoliduLogoFull } from 'components/SVGs/HoliduLogoFull'
import { SHLogo } from 'components/SVGs/SHLogo'
import React, { MouseEvent, useCallback, useState } from 'react'

import { messages } from '../../messages'
import { selectCurrentDomain } from '../../stores/selectors'

import { Navigation } from './Navigation/Navigation'
import { NavigationMobile } from './Navigation/NavigationMobile'

export const Header = () => {
  const currentDomain = selectCurrentDomain()
  const format = useIntl()

  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false)

  const handleMobileNavbarToggle = useCallback(
    (ev?: MouseEvent) => {
      ev?.preventDefault()
      setIsMobileNavbarOpen(open => !open)
    },
    [setIsMobileNavbarOpen],
  )

  const domainPrefixIndex = currentDomain.title.indexOf('.')
  const boldTitle = currentDomain.title.slice(0, domainPrefixIndex)
  const normalTitle = currentDomain.title.slice(domainPrefixIndex)

  return (
    <>
      <header className='header-bg-white'>
        <div className='header-container header-flex'>
          <div className='header__left_wrapper header-w-full'>
            <a
              id='SiteLogo'
              href='/'
              title={currentDomain.description}
              className='header__logo'
            >
              <div className='header__logo_container'>
                <SHLogo />
              </div>
              <div
                id='wl-spainholiday-header-navbar-title'
                className={cx('header-flex header__logo_text_wrapper')}
              >
                <span className='header__logo_text'>
                  <span className='header-whitespace-nowrap'>
                    <strong>{boldTitle}</strong>
                    <span>{normalTitle}</span>
                  </span>
                </span>
                <span className='header__logo_text--small header-text-black-ultra header__logo_container'>
                  {format(messages.LOGO_IN_COOPERATION_WITH)}
                  <span>
                    <HoliduLogoFull className='header-holidu-logo' />
                  </span>
                </span>
              </div>
            </a>
            <div className='header__buttons_wrapper'>
              <button
                type='button'
                id='wl-spainholiday-header-navigation-mobile-toggle'
                className='header__lang_settings_button'
                onClick={handleMobileNavbarToggle}
              >
                <Bars className='header-w-7 header-h-7' />
              </button>
            </div>
          </div>
          <div className='header__right_wrapper'>
            <Navigation />
          </div>
        </div>
      </header>
      <NavigationMobile
        isOpen={isMobileNavbarOpen}
        onClickToClose={handleMobileNavbarToggle}
      />
    </>
  )
}
