import { TypeOf } from 'io-ts'
import React from 'react'

import { ChevronRight } from '../../SVGs/ChevronRight'
import { Submenu } from '../../../decoders/PageData'

import { NavigationItem } from './NavigationItem'

export type Props = {
  children: React.ReactNode
  submenu: TypeOf<typeof Submenu>[]
}

export const NavigationDropdown = ({ children, submenu }: Props) => {
  return (
    <NavigationItem>
      <div className='header-dropdown header__navigation_dropdown group'>
        <a
          className='header__navigation_link ml-4'
          href='/'
          onClick={ev => ev.preventDefault()}
        >
          <div className='group-hover:bg-grey-light px-2 py-1 rounded-md flex items-center transition-colors'>
            {children}
            <ChevronRight className='header__chevron-right' />
          </div>
        </a>
        <div className='header__dropdown_menu-wrapper header-w-72'>
          <ul className='dropdown-menu dropdown_menu-container'>
            {submenu.map(({ text, href }) => (
              <li key={text}>
                <a href={href} className='dropdown-link'>
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </NavigationItem>
  )
}
