import { isLive } from '.'

export const INTERNAL_V4_URL = isLive
  ? 'https://api.holidu.com/old/rest/track/batch?domainId={domainId}&locale={locale}&currency={currency}'
  : 'https://dev-tracking-gateway.holidu.io/rest/track/batch?domainId={domainId}&locale={locale}&currency={currency}'

export const INTERNAL_V5_URL = isLive
  ? 'https://api.holidu.com/rest/trackBatch?domainId={domainId}&locale={locale}&currency={currency}'
  : 'https://dev-tracking-gateway.holidu.io/rest/trackBatch?domainId={domainId}&locale={locale}&currency={currency}'

export const CURRENCIES = [
  { code: 'AUD' },
  { code: 'BRL' },
  { code: 'CAD' },
  { code: 'CHF' },
  { code: 'DKK' },
  { code: 'EUR' },
  { code: 'GBP' },
  { code: 'MXN' },
  { code: 'NOK' },
  { code: 'NZD' },
  { code: 'PLN' },
  { code: 'SEK' },
  { code: 'USD' },
]
