import React, { ButtonHTMLAttributes } from 'react'

import { getButtonClassnames } from '../../utils/buttonUtils'

export type Props = ButtonHTMLAttributes<HTMLButtonElement>

export const SearchBarButton = ({
  className,
  children,
  disabled,
  ...others
}: Props) => (
  <button
    disabled={disabled}
    type='button'
    className={getButtonClassnames(
      disabled,
      'flex rounded-md justify-center items-center border-none',
      'bg-cta text-white cursor-pointer text-lg',
      'font-bold uppercase hover:bg-cta-hover',
      className,
    )}
    {...others}
  >
    {children}
  </button>
)
