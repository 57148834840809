import { PullstateCore } from './PullstateCore'

const getStore = () => PullstateCore!.useStores().AppStore

export const selectPageData = () => getStore().useState(state => state.pageData)
export const selectSliderTitle = () =>
  getStore().useState(state => state.pageData.sliderTitle)
export const selectUrls = () =>
  getStore().useState(state => state.pageData.urls)
export const selectCurrentDomain = () =>
  getStore().useState(state => state.currentDomain)
export const selectMessageData = () =>
  getStore().useState(state => state.messageData)
export const selectNavigationMenu = () =>
  getStore().useState(state => state.pageData.navigationMenu)
export const selectAboutUs = () =>
  getStore().useState(state => state.pageData.aboutUs)
export const selectDomainConfig = () =>
  getStore().useState(state => state.domainConfig)
export const selectUuid = () => getStore().useState(state => state.uuid)
export const selectUserId = () => getStore().useState(state => state.userId)
export const selectLocale = () => getStore().useState(state => state.locale!)
export const selectCurrency = () =>
  getStore().useState(state => state.currency!)
export const selectPopularDestinations = () =>
  getStore().useState(state => state.pageData.popularDestinations)
export const selectThemes = () =>
  getStore().useState(state => state.pageData.themes)
export const selectAvailableCurrencies = () =>
  getStore().useState(state => state.availableCurrencies)
export const selectInspirationItemsData = () =>
  getStore().useState(state => {
    return {
      inspirationItems: state.isDesktop
        ? [
            state.pageData.inspiration.desktopPrimaryItems,
            state.pageData.inspiration.desktopSecondaryItems,
          ]
        : [state.pageData.inspiration.mobileItems],
      h1Title: state.pageData.h1Title,
      h1Subtitle: state.pageData.h1Subtitle,
      h1Text: state.pageData.h1Text,
      isDesktop: state.isDesktop,
      textSeeMore: state.pageData.inspiration.textSeeMore,
    }
  })
export const selectFooterData = () =>
  getStore().useState(state => {
    return {
      footerMenu2Title: state.pageData.footerMenu2Title,
      footerMenu2: state.pageData.footerMenu2,
      footerMenu3Title: state.pageData.footerMenu3Title,
      footerMenu3: state.pageData.footerMenu3,
      footerMenuLanguagesTitle: state.pageData.footerMenuLanguagesTitle,
      copyrightText: state.pageData.copyrightText,
    }
  })
export const selectHomeUsps = () =>
  getStore().useState(state => state.pageData.homepageUsp)
export const selectMatchedHost = () =>
  getStore().useState(state => state.matchedHost)
