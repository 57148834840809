import React from 'react'

export type Props = {
  className?: string
}

export const HoliduLogoFull = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox='0 0 162 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32.632 18.8653C31.3965 20.2552 29.4232 20.5566 28.2392 19.5519C27.0551 18.5472 27.0894 16.6047 28.3249 15.2148C29.5605 13.8249 31.5338 13.5235 32.7178 14.5282C33.9018 15.533 33.8675 17.4754 32.632 18.8653Z'
        fill='#00809D'
      />
      <path
        d='M18.4561 24.0495V16.5398C18.4561 16.4504 18.4385 16.3619 18.4043 16.2793C18.3701 16.1967 18.32 16.1217 18.2568 16.0585C18.1936 15.9953 18.1186 15.9452 18.036 15.911C17.9534 15.8768 17.8649 15.8592 17.7755 15.8592H14.3299C14.2405 15.8592 14.152 15.8768 14.0694 15.911C13.9869 15.9452 13.9118 15.9953 13.8486 16.0585C13.7854 16.1217 13.7353 16.1967 13.7011 16.2793C13.6669 16.3619 13.6493 16.4504 13.6493 16.5398V24.0477C15.2495 24.1634 16.8559 24.164 18.4561 24.0495Z'
        fill='#00809D'
      />
      <path
        d='M35.3381 20.3491C35.398 20.3932 35.4481 20.4491 35.4854 20.5134L36.0582 21.5054C36.1251 21.6201 36.1568 21.752 36.1494 21.8847C36.1419 22.0173 36.0956 22.1448 36.0163 22.2513C35.099 23.4661 34.0481 24.5738 32.8833 25.5538V38.2619C32.8833 38.3512 32.8657 38.4397 32.8315 38.5223C32.7973 38.6049 32.7472 38.6799 32.684 38.7431C32.6208 38.8063 32.5457 38.8565 32.4632 38.8907C32.3806 38.9249 32.2921 38.9425 32.2027 38.9425H28.7571C28.5766 38.9425 28.4034 38.8708 28.2758 38.7431C28.1482 38.6155 28.0765 38.4424 28.0765 38.2619V28.5592C25.0557 29.8985 21.7517 30.4736 18.4561 30.2338V38.262C18.4561 38.3514 18.4385 38.4399 18.4043 38.5224C18.3701 38.605 18.3199 38.68 18.2567 38.7432C18.1935 38.8064 18.1185 38.8566 18.0359 38.8908C17.9534 38.925 17.8649 38.9426 17.7755 38.9426H14.3299C14.1493 38.9426 13.9762 38.8709 13.8486 38.7432C13.721 38.6156 13.6492 38.4425 13.6492 38.262V29.2982C12.4466 28.9068 11.2836 28.4026 10.1758 27.7923C10.0962 27.7486 10.0262 27.6894 9.96971 27.6183C9.91325 27.5471 9.87156 27.4654 9.84707 27.378C9.82258 27.2905 9.81578 27.199 9.82708 27.1089C9.83837 27.0188 9.86753 26.9318 9.91285 26.8531L10.4373 25.9448C10.5059 25.8266 10.6085 25.7318 10.7317 25.6727C10.8549 25.6136 10.993 25.5929 11.1282 25.6135C19.3721 26.842 27.7784 24.9611 34.7127 20.3365C34.7743 20.2948 34.8438 20.2663 34.9169 20.2526C34.9899 20.239 35.065 20.2405 35.1375 20.2571C35.21 20.2737 35.2783 20.305 35.3381 20.3491Z'
        fill='#00809D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.7933 0.984141L42.8341 10.2451C45.0733 11.5378 46.3571 13.6866 46.3565 16.1402L46.3509 40.1068C46.3625 41.4224 45.9805 42.7115 45.2539 43.8083C44.5274 44.9052 43.4894 45.7598 42.2734 46.2622C30.5446 51.2458 15.9785 51.2457 4.26121 46.2631C3.04577 45.7603 2.00835 44.9058 1.28218 43.8091C0.555999 42.7124 0.174217 41.4237 0.185856 40.1085L0.180054 16.1402C0.179553 13.6864 1.46353 11.5378 3.70254 10.2451L19.7432 0.984141C22.0162 -0.327898 24.5199 -0.328237 26.7933 0.984141ZM42.8456 42.2146C43.2595 41.5902 43.4742 40.855 43.4615 40.106L43.4671 16.1395C43.4675 14.7053 42.7491 13.5322 41.3895 12.7473L25.3487 3.48634C23.9488 2.67801 22.5872 2.67822 21.1878 3.48634L5.14719 12.7473C3.78751 13.5322 3.06902 14.7053 3.06934 16.1395L3.07516 40.1078C3.06241 40.8565 3.277 41.5915 3.69061 42.2158C4.10421 42.84 4.69739 43.3241 5.39184 43.6043C16.4131 48.2911 30.1123 48.2902 41.1435 43.6031C41.8383 43.3231 42.4318 42.8389 42.8456 42.2146Z'
        fill='#00809D'
      />
      <path
        d='M74.4837 18.2799V34.5085C74.4837 34.6817 74.4148 34.8478 74.2924 34.9703C74.1699 35.0928 74.0038 35.1616 73.8306 35.1616H71.0787C70.9054 35.1616 70.7393 35.0928 70.6169 34.9703C70.4944 34.8478 70.4256 34.6817 70.4256 34.5085V27.9724H61.9591V34.5085C61.9591 34.6817 61.8903 34.8478 61.7678 34.9703C61.6453 35.0928 61.4792 35.1616 61.306 35.1616H58.5541C58.3809 35.1616 58.2148 35.0928 58.0923 34.9703C57.9698 34.8478 57.901 34.6817 57.901 34.5085V18.2799C57.901 18.1067 57.9698 17.9406 58.0923 17.8181C58.2148 17.6956 58.3809 17.6268 58.5541 17.6268H61.306C61.4792 17.6268 61.6453 17.6956 61.7678 17.8181C61.8903 17.9406 61.9591 18.1067 61.9591 18.2799V24.5405H70.4256V18.2799C70.4256 18.1067 70.4944 17.9406 70.6169 17.8181C70.7393 17.6956 70.9054 17.6268 71.0787 17.6268H73.8306C74.0038 17.6268 74.1699 17.6956 74.2924 17.8181C74.4148 17.9406 74.4837 18.1067 74.4837 18.2799Z'
        fill='#00809D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M87.1824 17.3264C81.6463 17.3264 77.5883 21.1839 77.5883 26.3941C77.5883 31.6043 81.6463 35.4623 87.1824 35.4623C92.693 35.4623 96.7765 31.6298 96.7765 26.3941C96.7765 21.159 92.693 17.3264 87.1824 17.3264ZM87.1824 20.7832C90.3134 20.7832 92.668 23.0625 92.668 26.3941C92.668 29.7257 90.3134 32.0055 87.1824 32.0055C84.0509 32.0055 81.6963 29.7257 81.6963 26.3941C81.6963 23.0625 84.0509 20.7832 87.1824 20.7832Z'
        fill='#00809D'
      />
      <path
        d='M100.537 17.6268H103.288C103.462 17.6268 103.628 17.6956 103.75 17.8181C103.873 17.9406 103.941 18.1067 103.941 18.2799V31.8549H112.916C113.089 31.8549 113.255 31.9237 113.377 32.0461C113.5 32.1686 113.569 32.3347 113.569 32.508V34.5084C113.569 34.6816 113.5 34.8477 113.377 34.9702C113.255 35.0927 113.089 35.1615 112.916 35.1615H100.537C100.363 35.1615 100.197 35.0927 100.075 34.9702C99.9522 34.8477 99.8834 34.6816 99.8834 34.5084V18.2799C99.8834 18.1067 99.9522 17.9406 100.075 17.8181C100.197 17.6956 100.363 17.6268 100.537 17.6268Z'
        fill='#00809D'
      />
      <path
        d='M119.871 17.6268H117.119C116.946 17.6268 116.78 17.6956 116.657 17.8181C116.535 17.9406 116.466 18.1067 116.466 18.2799V34.5085C116.466 34.6817 116.535 34.8478 116.657 34.9703C116.78 35.0928 116.946 35.1616 117.119 35.1616H119.871C120.044 35.1616 120.21 35.0928 120.333 34.9703C120.455 34.8478 120.524 34.6817 120.524 34.5085V18.2799C120.524 18.1067 120.455 17.9406 120.333 17.8181C120.21 17.6956 120.044 17.6268 119.871 17.6268Z'
        fill='#00809D'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M124.584 18.2799C124.584 18.1067 124.653 17.9406 124.775 17.8181C124.898 17.6956 125.064 17.6268 125.237 17.6268H132.643C138.38 17.6268 142.116 21.0837 142.116 26.3941C142.116 31.7046 138.38 35.1615 132.643 35.1615H125.237C125.064 35.1615 124.898 35.0927 124.775 34.9702C124.653 34.8477 124.584 34.6816 124.584 34.5084V18.2799ZM132.475 31.8299C135.957 31.8299 138.039 29.7507 138.039 26.3941C138.039 23.0376 135.957 20.9584 132.475 20.9584H128.642V31.8299H132.475Z'
        fill='#00809D'
      />
      <path
        d='M145.22 18.2799V27.4465C145.22 32.6068 148.527 35.4624 153.512 35.4624C158.496 35.4624 161.803 32.6068 161.803 27.4465V18.2799C161.803 18.1942 161.786 18.1092 161.753 18.03C161.72 17.9507 161.672 17.8787 161.612 17.8181C161.551 17.7575 161.479 17.7094 161.4 17.6765C161.321 17.6437 161.236 17.6268 161.15 17.6268H158.448C158.275 17.6268 158.109 17.6956 157.987 17.8181C157.864 17.9406 157.795 18.1067 157.795 18.2799V27.2958C157.795 30.6273 156.017 32.0055 153.537 32.0055C151.082 32.0055 149.278 30.6274 149.278 27.2958V18.2799C149.278 18.1067 149.21 17.9406 149.087 17.8181C148.965 17.6956 148.799 17.6268 148.625 17.6268H145.873C145.7 17.6268 145.534 17.6956 145.412 17.8181C145.289 17.9406 145.22 18.1067 145.22 18.2799Z'
        fill='#00809D'
      />
    </svg>
  )
}
