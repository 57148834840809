import { messages as sharedMessages } from '@shared/universal/components/messages'

export const messages = {
  ...sharedMessages,
  LOGO_IN_COOPERATION_WITH: {
    id: 'LOGO_IN_COOPERATION_WITH',
    defaultValue: 'In cooperation with',
  },
  COOKIES_SETTINGS_FOOTER: {
    id: 'COOKIES_SETTINGS_FOOTER',
    defaultValue: 'Cookie preferences',
  },
}

export const messageKeys = Object.values(messages).map(({ id }) => id)
