import React, { HTMLAttributes, useMemo } from 'react'
import { addMonths, compareAsc } from 'date-fns'

import { ChevronRight } from '../SVGs/ChevronRight'

import { Calendar } from './Calendar'
import { MonthController } from './MonthController'

export type Props = {
  checkinDay?: Date
  checkoutDay?: Date
  onDayClick: (day: Date) => void
  hoverDay?: Date
  onDayHover: (day?: Date) => void
  updateMonth: (amount: number) => () => void
  month: Date
  weekStartsOn: 0 | 1
} & HTMLAttributes<HTMLDivElement>

export const DatePicker = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    (
      {
        checkinDay,
        checkoutDay,
        onDayClick,
        hoverDay,
        onDayHover,
        updateMonth,
        month,
        weekStartsOn,
        className,
        ...others
      },
      ref,
    ) => {
      const nextMonth = useMemo(() => addMonths(month, 1), [month])
      const checkoutOrHoveredDay =
        checkoutDay ||
        (checkinDay && hoverDay && compareAsc(hoverDay, checkinDay) === 1
          ? hoverDay
          : undefined)

      return (
        <div ref={ref} className={className}>
          <div
            className='relative py-5 px-8 bg-white md:w-calendar-desktop'
            {...others}
          >
            <MonthController onClick={updateMonth(-1)} className='left-6'>
              <ChevronRight className='transform rotate-180' />
            </MonthController>
            <MonthController onClick={updateMonth(1)} className='right-6'>
              <ChevronRight />
            </MonthController>
            <div className='flex z-calendar w-full'>
              {[...Array(2).keys()].map(idx => (
                <Calendar
                  weekStartsOn={weekStartsOn}
                  className={idx === 0 ? 'md:mr-5 hidden md:flex' : 'md:ml-5'}
                  titleClassName={idx === 0 ? 'md:ml-5' : 'md:mr-5'}
                  key={idx}
                  onDayHover={onDayHover}
                  onDayClick={onDayClick}
                  checkinDay={checkinDay}
                  checkoutDay={checkoutOrHoveredDay}
                  month={idx === 0 ? month : nextMonth}
                />
              ))}
            </div>
          </div>
        </div>
      )
    },
  ),
)

DatePicker.displayName = 'DatePicker'
