import React, { useCallback, useMemo, useContext } from 'react'
import cx from 'classnames'
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInWeeks,
  addDays,
  isSameDay,
  isWithinInterval,
  isPast,
  isWeekend,
} from 'date-fns'

import { has } from '../../types/index'
import { IntlContext } from '../../utils/intl'

import { Day, Props as DayProps } from './Day'
import { locales } from './locales'

export type Props = {
  weekStartsOn: 0 | 1
  className?: string
  titleClassName?: string
  month: Date
  checkinDay?: Date
  checkoutDay?: Date
  onDayClick: (day: Date) => void
  onDayHover: (day?: Date) => void
}

export const Calendar = ({
  weekStartsOn,
  className,
  titleClassName,
  month,
  checkinDay,
  checkoutDay,
  onDayClick,
  onDayHover,
}: Props) => {
  const { locale } = useContext(IntlContext)
  const supportedLocale = has(locales, locale) ? locales[locale] : locales.en
  const title = format(month, 'MMMM y', { locale: supportedLocale })
  const monthStart = startOfMonth(month)
  const start = startOfWeek(monthStart, { weekStartsOn })
  const monthEnd = endOfMonth(month)
  const end = endOfWeek(monthEnd, { weekStartsOn })
  const totalWeeks = differenceInWeeks(addDays(end, 1), start)

  const handleDayMouseEnter = useCallback(
    (day: Date) => onDayHover(day),
    [onDayHover],
  )
  const handleDayClick = useCallback(
    (day: Date) => onDayClick(day),
    [onDayClick],
  )

  const handleMouseLeave = () => onDayHover(undefined)

  const weekNames = useMemo(() => {
    let temp = startOfWeek(new Date(), { weekStartsOn })
    return (
      <div className='flex mb-5 text-grey-dark'>
        {[...Array(7).keys()].map(idx => {
          const component = (
            <div
              key={idx}
              className={cx('w-1/7 text-center uppercase text-xs', {
                'font-semibold': isWeekend(temp),
              })}
            >
              {format(temp, 'E', { locale: supportedLocale })}
            </div>
          )
          temp = addDays(temp, 1)
          return component
        })}
      </div>
    )
  }, [weekStartsOn, supportedLocale])

  let day = start
  const content = [...Array(totalWeeks).keys()].map(idx => (
    <div key={idx} className='flex mb-1'>
      {[...Array(7).keys()].map(() => {
        let component: React.ReactNode
        const isPlaceholder =
          day.getTime() >= monthStart.getTime() &&
          day.getTime() <= monthEnd.getTime()
        if (isPlaceholder) {
          const dayProps: DayProps = { day: day.getDate(), time: day.getTime() }
          dayProps.isCheckin = Boolean(checkinDay && isSameDay(day, checkinDay))
          dayProps.isCheckout = Boolean(
            checkoutDay && isSameDay(day, checkoutDay),
          )

          dayProps.isBetween = false
          if (checkinDay) {
            if (checkoutDay) {
              dayProps.isBetween = isWithinInterval(day, {
                start: checkinDay,
                end: checkoutDay,
              })
            }
          }

          dayProps.isUnavailable = isPast(day)

          if (!dayProps.isUnavailable) {
            dayProps.onMouseEnter = handleDayMouseEnter
          }

          if (!dayProps.isUnavailable) {
            dayProps.onClick = handleDayClick
          }

          dayProps.isStrong = isWeekend(day) && !dayProps.isUnavailable
          component = <Day key={day.getTime()} {...dayProps} />
        } else {
          component = (
            <div
              key={day.getTime()}
              className='w-1/7 h-1/7 bg-white flex-shrink-0'
            />
          )
        }
        day = addDays(day, 1)
        return component
      })}
    </div>
  ))

  return (
    <div className={cx('flex flex-1 flex-col bg-white', className)}>
      <div
        className={cx(
          'font-bold text-center pt-2 pb-8 text-black-main',
          titleClassName,
        )}
      >
        {title}
      </div>
      {weekNames}
      <div onMouseLeave={handleMouseLeave}>{content}</div>
    </div>
  )
}
