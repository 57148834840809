import cx, { Argument } from 'classnames'

export const getButtonClassnames = (
  disabled: boolean | undefined,
  ...args: Argument[]
) =>
  cx(
    'appearance-none outline-none',
    {
      'cursor-default': disabled,
      'cursor-pointer': !disabled,
    },
    ...args,
  )
