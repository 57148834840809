import { compareAsc, addMonths } from 'date-fns'
import { useCallback, useRef, useState } from 'react'

import { useOnClickOutside } from '../hooks/useOutsideClick'

export const useDateInput = ({
  handleDateSelection,
}: {
  handleDateSelection?: (checkin: Date, checkout: Date) => void
}) => {
  const datePickerRef = useRef<HTMLDivElement>(null)
  const checkinInputRef = useRef<HTMLButtonElement>(null)
  const checkoutInputRef = useRef<HTMLButtonElement>(null)

  const removeEventListeners = useOnClickOutside(
    [datePickerRef, checkinInputRef, checkoutInputRef],
    () => {
      setIsCheckinInputActive(false)
      setIsCheckoutInputActive(false)
    },
  )

  const [checkinDay, setCheckinDay] = useState<Date>()
  const [checkoutDay, setCheckoutDay] = useState<Date>()
  const [hoverDay, setHoverDay] = useState<Date>()
  const [month, setMonth] = useState<Date>(new Date())
  const [isCheckinInputActive, setIsCheckinInputActive] = useState(false)
  const [isCheckoutInputActive, setIsCheckoutInputActive] = useState(false)

  const updateMonth = useCallback(
    (amount: number) => () => {
      setMonth(month => addMonths(month, amount))
    },
    [],
  )

  const setCheckinDayAndRelatedFields = (day: Date) => {
    setCheckinDay(day)
    setIsCheckinInputActive(false)
    setIsCheckoutInputActive(true)
  }

  const setCheckinDayAndResetCheckoutDay = useCallback((day: Date) => {
    setCheckoutDay(undefined)
    setCheckinDayAndRelatedFields(day)
  }, [])

  // @TODO: Implement this function in a better way. It causes unnecessary costly re-renders because of bad deps list.
  // Maybe just combine all the states in the deps into one state so that they can be updated using a single state setter function.
  const handleDayClick = useCallback(
    (day: Date) => {
      if (!checkinDay) {
        setCheckinDayAndRelatedFields(day)
      } else if (checkoutDay && compareAsc(day, checkoutDay) === 0) {
        setCheckinDayAndResetCheckoutDay(day)
      } else if (compareAsc(day, checkinDay) === 1) {
        if (checkoutDay) {
          if (isCheckinInputActive) {
            setCheckinDayAndResetCheckoutDay(day)
          } else {
            setCheckoutDay(day)
            handleDateSelection?.(checkinDay, day)
          }
        } else {
          setCheckoutDay(day)
          setIsCheckinInputActive(false)
          setIsCheckoutInputActive(false)
          handleDateSelection?.(checkinDay, day)
        }
      } else {
        setCheckinDayAndResetCheckoutDay(day)
      }
    },
    [
      checkinDay,
      checkoutDay,
      isCheckinInputActive,
      handleDateSelection,
      setCheckinDayAndResetCheckoutDay,
    ],
  )

  const handleDayHover = useCallback((day?: Date) => {
    setHoverDay(day)
  }, [])

  const handleCheckinInputClick = () => {
    setIsCheckinInputActive(true)
    setIsCheckoutInputActive(false)
  }

  const handleCheckoutInputClick = () => {
    setIsCheckoutInputActive(true)
    setIsCheckinInputActive(false)
  }

  return {
    handleDayClick,
    handleDayHover,
    handleCheckinInputClick,
    handleCheckoutInputClick,
    updateMonth,
    removeEventListeners,
    checkinDay,
    checkoutDay,
    hoverDay,
    month,
    isCheckinInputActive,
    isCheckoutInputActive,
    datePickerRef,
    checkinInputRef,
    checkoutInputRef,
  }
}
