import { TypeOf } from 'io-ts'
import React from 'react'

import { Collapse } from '../../Collapse'
import { Submenu } from '../../../decoders/PageData'

export type Props = {
  children: React.ReactNode
  submenu: TypeOf<typeof Submenu>[]
}

export const NavigationCollapse = ({ children, submenu }: Props) => {
  const toggle = (
    <button
      type='button'
      className='border-0 outline-none p-0 header-text-black-ultra'
      onClick={ev => ev.preventDefault()}
    >
      {children}
    </button>
  )

  return (
    <Collapse
      toggle={toggle}
      toggleClassName='header-text-black-ultra mobile-navigation-button wl-spainholiday-header-collapse-toggle'
    >
      <ul className='site-settings-list'>
        {submenu.map(({ text, href }) => (
          <li key={text}>
            <a
              className='mobile-navigation-button header-text-black-ultra pl-7'
              href={href}
            >
              {text}
            </a>
          </li>
        ))}
      </ul>
    </Collapse>
  )
}
