import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

import { DatePicker } from '../DatePicker'
import { SearchBarDropdown } from '../SearchBarDropdown/SearchBarDropdown'
import { messages } from '../messages'
import { useIntl } from '../../utils/intl'

import { useDateInput } from './hooks'
import { DateInputButton } from './DateInputButton'

export type Props = ReturnType<typeof useDateInput> &
  HTMLAttributes<HTMLDivElement>

export const DateInput = ({
  className,
  handleDayClick,
  handleDayHover,
  handleCheckinInputClick,
  handleCheckoutInputClick,
  updateMonth,
  checkinDay,
  checkoutDay,
  hoverDay,
  month,
  isCheckinInputActive,
  isCheckoutInputActive,
  datePickerRef,
  checkinInputRef,
  checkoutInputRef,
}: Props) => {
  const format = useIntl()

  const isDatePickerVisible = isCheckinInputActive || isCheckoutInputActive
  const inputDate = isCheckinInputActive && hoverDay ? hoverDay : checkinDay
  const outputDate = isCheckoutInputActive && hoverDay ? hoverDay : checkoutDay

  const buttons = (
    <>
      <DateInputButton
        ref={checkinInputRef}
        onClick={handleCheckinInputClick}
        isActive={isCheckinInputActive}
        date={inputDate}
        placeholder={format(messages.CHECK_IN)}
      />
      <DateInputButton
        ref={checkoutInputRef}
        onClick={handleCheckoutInputClick}
        isActive={isCheckoutInputActive}
        date={outputDate}
        placeholder={format(messages.CHECK_OUT)}
        className='ml-2'
      />
    </>
  )

  return (
    <SearchBarDropdown
      buttons={buttons}
      isMenuOpen={isDatePickerVisible}
      className={cx(className, 'lg:relative')}
    >
      <DatePicker
        ref={datePickerRef}
        className={cx({
          'search-bar-menu-triangle': isDatePickerVisible,
          'triangle-left': isCheckinInputActive,
          'triangle-right': isCheckoutInputActive,
        })}
        onDayHover={handleDayHover}
        onDayClick={handleDayClick}
        updateMonth={updateMonth}
        month={month}
        checkinDay={checkinDay}
        checkoutDay={checkoutDay}
        weekStartsOn={1 /* @TODO: Dynamically set */}
        hoverDay={hoverDay}
      />
    </SearchBarDropdown>
  )
}
