import {
  enUS,
  de,
  pl,
  da,
  nb,
  es,
  nl,
  fr,
  it,
  pt,
  sv,
  ru,
} from 'date-fns/locale'

export const locales = { en: enUS, de, pl, da, nb, es, nl, fr, it, pt, sv, ru }
