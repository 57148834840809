import { MessageData } from '@holidu/lib/server/intl/types'
import { useCallback, useContext, createContext } from 'react'

export type IntlContextType = {
  messageData: MessageData
  locale: string
}

export const IntlContext = createContext<IntlContextType>({
  messageData: {},
  locale: 'en',
})

export const useIntl = () => {
  const { messageData } = useContext(IntlContext)

  const format = useCallback(
    (
      {
        id,
        defaultValue,
      }: {
        id: string
        defaultValue: string
      },
      params?: Record<string, any>,
    ) => {
      const message = messageData[id] || defaultValue
      if (params) {
        return Object.keys(params).reduce((acc, key) => {
          const regex = new RegExp(`{{${key}}}`, 'g')
          return acc.replace(regex, params[key])
        }, message)
      }
      return message
    },
    [messageData],
  )

  return format
}
